export default function Sketch(p5) {
    let canvas

    p5.setup = () => {
        // canvas = p5.createCanvas(400, 400);
        canvas = p5.createCanvas(p5.windowWidth, p5.windowHeight);

        // p5.saveCanvas(canvas, "fld.jpg");
    };

    p5.draw = () => {
        p5.background(40);
        for (let i = 0; i < 10; i++)

            p5.push()

        p5.ellipse(p5.width / 2, p5.height / 2, p5.random(40 - 20) * 4);
        p5.noFill();
        p5.stroke("#fff");
        p5.ellipse(p5.width / 2, p5.height / 2, p5.random(40 - 20) * 4);
        for (let j = 0; j < 10; j++) {
            p5.ellipse(p5.width / 2, p5.height / 2, j + p5.random(40 - 20) * 40);
        }
        p5.noFill();
        p5.translate(-175, -175);
        p5.stroke("#fff");

        p5.pop()
    };

    p5.windowResized = () => {
        p5.resizeCanvas(p5.windowWidth, p5.windowHeight);
    }

};
