import React from "react"
// import { Link } from "gatsby"
import SEO from "../components/seo"

import GlobalStyle from "../components/globalStyle"
import styled from "styled-components"
import Hero from "../components/hero"

import P5 from "../components/p5/p5_1/p5"

const Container = styled.div``
const InfiniteRandomCircles = () => (
  <Container>
    <SEO title="Infinite random circles" />
    <GlobalStyle />
    <Hero />
    <P5 />
    {/* <Link to="/">Home</Link> <br /> */}
  </Container>
)

export default InfiniteRandomCircles
